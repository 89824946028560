import { Box, Button, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";

const StatusChange = ({ setSubmitting, user, databyEmail, SetMenuItems }) => {
	const [openStatus, setStatus] = React.useState(false);
	const handleClose = () => {
		setStatus(false);
	};

	const handleOpen = () => {
		setStatus(true);
	};
	return (
		<Box sx={{ display: "flex" }}>
			<Button
				sx={{ minWidth: "190px", py: 1, height: "40px", my: "auto" }}
				onClick={handleOpen}>
				{SetMenuItems(user?.status) || "N/A"}
			</Button>
			<Select
				sx={{ visibility: "hidden", mr: -17 }}
				open={openStatus}
				onClose={handleClose}
				onOpen={handleOpen}
				onChange={(e) => {
					Swal.fire({
						title: "Are you sure?",
						text:
							e.target.value === "Closed" &&
							"You won't be able to revert this!",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: `Yes, Change Status!`,
					}).then((result) => {
						if (result.isConfirmed) {
							if (e.target.value === "Closed") {
								setSubmitting(true);
								axios
									.post(`https://americasolarpros.herokuapp.com/successfullSells`, {
										customer: user?.name,
										customerPhone: user?.phoneNumber,
										invitedBy: user?.invitationCode,
										assignedTo: user?.assignedTo,
										scheduleType: user?.scheduleType,
										product: user?.selectedProduct,
										soldOn: new Date().toLocaleString("en-US", {
											timeZone: "America/Los_Angeles",
											year: "numeric",
											month: "2-digit",
											day: "2-digit",
											hour: "2-digit",
											minute: "2-digit",
											second: "2-digit",
											timeZoneName: "short",
										}),
										paidStatus: "N/A",
										paidAmount: 0,
										paidOn: "N/A",
										note: "",
										invitedRecrutedBy: user?.invitedRecrutedBy,
										recruterPaidStatus: "N/A",
										recruterPaidAmount: 0,
										recruterPaidOn: "N/A",
										recruterNote: "",
									})
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Updated",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}
							axios
								.put(`https://americasolarpros.herokuapp.com/status/${user?._id}`, {
									status: e.target.value,
									time: new Date().toLocaleString("en-US", {
										timeZone: "America/Los_Angeles",
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
										timeZoneName: "short",
									}),
								})
								.then(function (response) {
									setSubmitting(false);
									Swal.fire({
										icon: "success",
										title: "Successfully Updated",
										showConfirmButton: false,
										timer: 1500,
									});
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							setSubmitting(false);
						}
					});
				}}
				defaultValue={user?.status}>
				<MenuItem value={"Pending"}>Pending</MenuItem>
				<MenuItem value={"Pre-site-survey"}>Sold</MenuItem>
				{databyEmail?.role === "Admin" && (
					<MenuItem value={"Closed"}>Close</MenuItem>
				)}
				<MenuItem value={"Post-site-survey"}>Post site survey</MenuItem>
				<MenuItem value={"Design-started"}>Design started</MenuItem>
				<MenuItem value={"Phone-Room-Follow-Up"}>Phone Room Follow Up</MenuItem>
				<MenuItem value={"Manager-Follow-Up"}>Rehash</MenuItem>
				<MenuItem value={"Canceled"}>Canceled</MenuItem>
				<MenuItem value={"Agent-Follow-Up"}>Agent Follow Up</MenuItem>
				<MenuItem value={"DNQ"}>DNQ</MenuItem>
				<MenuItem value={"Water-Deal"}>Water</MenuItem>
				<MenuItem value={"In-Permitting"}>In Permitting</MenuItem>
				<MenuItem value={"Install-Scheduled"}>Install Scheduled</MenuItem>
				<MenuItem value={"M2-Funded"}>Funded</MenuItem>
			</Select>
		</Box>
	);
};

export default StatusChange;
